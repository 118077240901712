import React, { useState } from "react";
import Image from "gatsby-image";

import "./Project.scss";
import Wave from "../Wave";

const Project = ({
    project,
    image,
    imageOrder,
    waveTop,
    waveBottom
}) => {
    const {
        name,
        technologies,
        description,
        link,
        backgroundColor,
        linkBorderColor,
        shadowColor
    } = project;

    const [ linkStyle, setLinkStyle] = useState({
        border: `2px solid ${linkBorderColor}`,
        background: linkBorderColor
    });

    const onLinkHover = setToDefault => {
        setToDefault
            ? (
                setLinkStyle({
                    border: `2px solid ${linkBorderColor}`,
                    background: linkBorderColor,
                    color: "#FFF"
                })
            ) : (
                setLinkStyle({
                    border: `2px solid #FFF`,
                    background: "#FFF",
                    color: backgroundColor
                })
            )
    };

    return (
        <div
            className="project"
            style={{ backgroundColor: backgroundColor }}
        >
            {waveTop.display && <Wave {...waveTop} />}
            <div className="lg:flex-row lg:flex container mx-auto px-4 md:px-o text-center mb-3 content">
                <div
                    className={`flex flex-col mx-auto pb-2 lg:w-1/2 lg:pb-0 px-4 ${imageOrder === "first" ? "order-last" : "order-first"}`}
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <h2 className="heading inverse mb-4">{name}</h2>
                    <div className="px-3 lg:px-0 flex flex-col flex-grow justify-between">
                        <div>
                            <p className="text-xl italic underline mb-1">Technologies</p>
                            <p className="mb-6">
                                {technologies && technologies.map((technology, index) => (
                                    index === technologies.length - 1
                                        ? technology
                                        : `${technology}, `
                                ))}
                            </p>
                            <p className="text-xl italic underline mb-1">Description</p>
                            <p className="mb-6">{description}</p>
                        </div>
                        <a
                            className="px-2 py-1 mx-auto text-sm hidden lg:block"
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={linkStyle}
                            onMouseEnter={() => onLinkHover(false)}
                            onMouseOut={() => onLinkHover(true)}
                            aria-label={`Link to ${name}`}
                        >
                            Take Me There
                        </a>
                    </div>
                </div>
                <div className={`px-3 lg:w-1/2 lg:px-0 mx-auto ${imageOrder === "first" ? "order-first" : "order-last"}`}>
                    <div
                        className="project-image mx-auto"
                        style={{
                            boxShadow: `0 10px 20px rgba(${shadowColor}, 0.19), 0 6px 6px rgba(${shadowColor},0.23)`
                        }}
                    >
                        <Image
                            fluid={image}
                            maxWidth={480}
                        />
                    </div>
                </div>
                <div className="my-8 lg:my-4 lg:hidden">
                    <a
                    className="px-2 py-1 mx-auto my-2 text-sm"
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={linkStyle}
                    onMouseEnter={() => onLinkHover(false)}
                    onMouseOut={() => onLinkHover(true)}
                    aria-label={`Link to ${name}`}
                    >
                        Take Me There
                    </a>
                </div>
            </div>
            {waveBottom.display && <Wave {...waveBottom} />}
        </div>
    );
};

export default Project;