import React from "react";

const Wave = ({ style, backgroundColor, fillColor, isTop }) => {
    const getDrawnPath = () => {
        let selectedPath;

        switch (style) {
            case 0:
                selectedPath = isTop
                    ? "M0,192L80,202.7C160,213,320,235,480,208C640,181,800,107,960,112C1120,117,1280,203,1360,245.3L1440,288L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                    : "M0,192L80,202.7C160,213,320,235,480,208C640,181,800,107,960,112C1120,117,1280,203,1360,245.3L1440,288L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z";
                break;
            case 1:
                selectedPath = isTop
                    ? "M0,192L120,176C240,160,480,128,720,117.3C960,107,1200,117,1320,122.7L1440,128L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                    : "M0,192L120,176C240,160,480,128,720,117.3C960,107,1200,117,1320,122.7L1440,128L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z";
                break;
            case 2:
                selectedPath = isTop
                    ? "M0,128L120,144C240,160,480,192,720,197.3C960,203,1200,181,1320,170.7L1440,160L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                    : "M0,128L120,144C240,160,480,192,720,197.3C960,203,1200,181,1320,170.7L1440,160L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z";
                break;
            case 3:
                selectedPath = isTop
                    ? "M0,32L120,37.3C240,43,480,53,720,74.7C960,96,1200,128,1320,144L1440,160L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                    : "M0,32L120,37.3C240,43,480,53,720,74.7C960,96,1200,128,1320,144L1440,160L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z";
                break;
            case 4:
                selectedPath = isTop
                    ? "M0,192L120,176C240,160,480,128,720,117.3C960,107,1200,117,1320,122.7L1440,128L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                    : "M0,192L120,176C240,160,480,128,720,117.3C960,107,1200,117,1320,122.7L1440,128L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z";
                break;
            case 5:
                selectedPath = isTop
                    ? "M0,128L120,144C240,160,480,192,720,197.3C960,203,1200,181,1320,170.7L1440,160L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                    : "M0,128L120,144C240,160,480,192,720,197.3C960,203,1200,181,1320,170.7L1440,160L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z";
                break;
            case 6:
                selectedPath = isTop
                    ? "M0,192L80,202.7C160,213,320,235,480,208C640,181,800,107,960,112C1120,117,1280,203,1360,245.3L1440,288L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                    : "M0,192L80,202.7C160,213,320,235,480,208C640,181,800,107,960,112C1120,117,1280,203,1360,245.3L1440,288L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z";
                break;
            default:
                selectedPath = isTop
                    ? "M0,192L80,202.7C160,213,320,235,480,208C640,181,800,107,960,112C1120,117,1280,203,1360,245.3L1440,288L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                    : "M0,192L80,202.7C160,213,320,235,480,208C640,181,800,107,960,112C1120,117,1280,203,1360,245.3L1440,288L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z";
                break;
        }

        return selectedPath;
    };

    return (
        <div style={{ backgroundColor: isTop ? "#FFF" : backgroundColor }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path
                    fill={fillColor}
                    fillOpacity="1"
                    d={getDrawnPath()}
                ></path>
            </svg>
        </div>
    );
};

export default Wave;
